<template>
    <div class="copyright-box" id="copyright-box">
        <title-nav v-if="$store.state.userAgent === 'Mobile'"/>
        <div class="content">
            <img :src="copyRightUrl" alt="" width="100%">
        </div>
    </div>
</template>

<script>
import TitleNav from '../../components/nav/TitleNav.vue'
const seo = require('../../../config/index')

export default {
  name: 'Copyright',
  components: {
    TitleNav
  },
  mounted(){
    if(this.$store.state.userAgent !== 'Mobile'){
        document.getElementById("copyright-box").style.cssText= 'margin-top: 98px;height:100%';
    }
  },
  data(){
      return {
          copyRightUrl: seo.config.homeUrl + '/public/images/copyright.png'
      }
  },
  methods:{

  },
  props:{

  }
}
</script>

<style scoped lang="scss">
.copyright-box {
    background: #fff;
    min-height: 100vh;
    box-sizing: border-box;
    .content {
        margin: 50px 10px 10px;
        padding-top: 10px;
        div {
            padding:5px 0;
        }
        .title {
            text-align: left;
            font-size: 22px;
        }
        .answer {
            text-align: left;
            color: rgba(0,0,0,0.5)
        }
    }
}
</style>
