import { render, staticRenderFns } from "./Copyright.vue?vue&type=template&id=4d345840&scoped=true&"
import script from "./Copyright.vue?vue&type=script&lang=js&"
export * from "./Copyright.vue?vue&type=script&lang=js&"
import style0 from "./Copyright.vue?vue&type=style&index=0&id=4d345840&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d345840",
  null
  
)

export default component.exports